<template>
  <div class="addressSelect flex spacebetween ">
    <el-select style="width:176px;"
               @change="handleChangeProvince"
               :disabled="selectTurn[0]"
               v-model="addressbind[0]"
               placeholder="请选择省份">
      <el-option v-for="(im,ix) in province"
                 :key="ix"
                 :label="im.full_name"
                 :value="im.id">
      </el-option>
    </el-select>
    <el-select style="width:176px;margin-left:20;"
               @change="handleChangeCities"
               :disabled="selectTurn[1]"
               v-model="addressbind[1]"
               placeholder="请选择城市">
      <el-option v-for="(im,ix) in cities"
                 :key="ix"
                 :label="im.full_name"
                 :value="im.id">
      </el-option>
    </el-select>
    <el-select style="width:176px;margin-left:20;"
               @change="handleChangeCounty"
               :disabled="selectTurn[2]"
               v-model="addressbind[2]"
               placeholder="请选择地区">
      <el-option v-for="(im,ix) in county"
                 :key="ix"
                 :label="im.full_name"
                 :value="im.id">
      </el-option>
    </el-select>
  </div>
</template>
<script>
import { get_address } from '@/utils/Api/commonList'
export default {
  name: 'addressSelect',
  props: {
    /* 省字段 */
    provice: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      isFirst: true,
      addressbind: [],
      // addressbindValue: [],
      selectTurn: [false, true, true],
      province: [],
      cities: [],
      county: []
    }
  },
  mounted () {
    // console.log('11111111111')
  },
  methods: {
    assignaddressbinds (value) {
      this.addressbinds = value
      this.initial()
    },
    /* 获取省 */
    async initial () {
      let pro = await get_address({ type: 1 })
      this.province = pro ? pro : this.$message.error('省份获取失败')
      if (this.addressbinds[1]) {
        this.addressbind[0] = this.addressbinds[0]
        this.handleChangeProvince(this.addressbinds[0])
        this.addressbinds[0] = null
      }
    },
    /* 获取市  选中省触发事件 */
    async handleChangeProvince (value) {
      // this.addressbindValue[0] = this.province.find(c => c.id == value).full_name
      /* 触发置空二三级绑定值 以及 二三级开关 */
      this.cities = []
      this.selectTurn[1] = true
      this.addressbind[1] = ''
      this.selectTurn[2] = true
      this.addressbind[2] = ''
      let city = await get_address({ type: 2, parent_id: value })
      this.cities = city ? city : this.$message.error('城市获取失败')
      this.selectTurn[1] = false
      if (this.addressbinds[2]) {
        this.addressbind[1] = this.addressbinds[1]
        // console.log(this.addressbinds[1], this.cities)
        this.handleChangeCities(this.addressbinds[1])
        this.addressbinds[1] = null
      }
    },
    /* 获取区  选中市触发事件 */
    async handleChangeCities (value) {
      // this.addressbindValue[1] = this.cities.find(c => c.id == value).full_name
      /* 触发置空三级绑定值 以及 三级开关 */
      this.county = []
      this.selectTurn[2] = true
      this.addressbind[2] = ''
      let area = await get_address({ type: 3, parent_id: value })
      this.county = area ? area : this.$message.error('地区获取失败')
      this.selectTurn[2] = false
      if (this.addressbinds[2]) {
        this.addressbind[2] = this.addressbinds[2]
        this.addressbinds[2] = null
      }
      if (this.isFirst) {
        this.isFirst = false
        this.$emit('proviceSE', this.addressbind, this.provice)
      }
    },
    /* 选中区触发事件 */
    async handleChangeCounty (value) {
      // this.addressbindValue[2] = this.county.find(c => c.id == value).full_name
    }
  },
  watch: {
    addressbind () {
      this.$emit('proviceSE', this.addressbind, this.provice)
      // var arr = []
      // if (this.province.find(c => c.id == this.addressbind[0])) {
      //   arr.push(this.province.find(c => c.id == this.addressbind[0]).full_name)
      //   if (this.cities.find(c => c.id == this.addressbind[1])) {
      //     arr.push(this.cities.find(c => c.id == this.addressbind[1]).full_name)
      //   }
      //   if (this.county.find(c => c.id == this.addressbind[2])) {
      //     arr.push(this.county.find(c => c.id == this.addressbind[2]).full_name)
      //   }
      // }
      // this.$emit('proviceSEV', arr)
    },
    addressbinds () {
      this.initial()
    }
  }
}
</script>
<style lang="scss" scoped>
.addressSelect {
  width: 100%;
}
</style>