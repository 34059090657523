<template>
    <div class="cominforOrg">
        <table>
            <tr>
                <td style="width:110px;text-align:left;">{{edit ? '医院机构全称' : '医院名称'}}</td>
                <td style="width:307px;">
                    <el-input v-model="org.hospital.full_name" :placeholder="edit ? '请填写医院机构全称' : '请填写医院名称'"></el-input>
                </td>
                <td style="width:110px;text-align:left;">管理类别</td>
                <td style="width:307px;">
                    <!-- <el-input v-model="org.hospital.manage_type" placeholder="请填写管理级别"></el-input> -->
					<el-select v-model="org.hospital.manage_type" placeholder="请选择管理级别" style="width:307px;">
					    <el-option
					    v-for="item in cominfor.managementcategory"
					    :key="item.id"
					    :label="item.name"
					    :value="item.name">
					    </el-option>
					</el-select>
                </td>
            </tr>
            <tr>
                <td style="width:110px;text-align:left;">机构类别</td>
                <td style="width:307px;">
                    <!-- <el-input v-model="org.hospital.organ_type" placeholder="请填写机构类型"></el-input> -->
					<el-select v-model="org.hospital.organ_type" placeholder="请选择机构类型" style="width:307px;">
					    <el-option
					    v-for="item in cominfor.organizationtype"
					    :key="item.id"
					    :label="item.name"
					    :value="item.name">
					    </el-option>
					</el-select>
                </td>
                <td style="width:110px;text-align:left;">医院级别</td>
                <td style="width:307px;">
                    <!-- <el-input v-model="org.hospital.level" placeholder="请填写医院级别"></el-input> -->
					<el-select v-model="org.hospital.level" placeholder="请选择医院级别" style="width:307px;">
					    <el-option
					    v-for="item in cominfor.hospitaillevel"
					    :key="item.id"
					    :label="item.name"
					    :value="item.name">
					    </el-option>
					</el-select>
                </td>
            </tr>
            <!-- <tr>
                <td style="width:110px;text-align:left;">固定电话</td>
                <td style="width:307px;">
                    <el-input v-model="org.hospital.tel" placeholder="请填写医院固定电话"></el-input>
                </td>
            </tr> -->
        </table>
        <div class="" style="width:870px;margin-top:25px;">
            <div class="flex spacebetween " style="width:700px;">
                <div style="width:110px;text-align:left;" class="flex alignCenter">选择地区</div>
                <div class="" style="width:609px;">
                    <address-select :provice="'org'" ref="orgInfoAddress" @proviceSE="proviceSE" ></address-select>
                </div>
            </div>
        </div>
        <div class="" style="width:870px;margin-top:25px;">
            <div class="flex spacebetween " style="width:700px;">
                <div style="width:110px;text-align:left;" class="flex alignCenter">详细地址</div>
                <div style="width:610px;" class="flex spacebetween ">
                    <el-input v-model="org.hospital.address" style="width:390px" placeholder="请输入详细地址"></el-input>
                    <div></div>
                </div>
            </div>
        </div>
        <div class="" style="width:870px;margin-top:25px;">
            <div class="flex spacebetween " style="width:700px;">
                <div style="width:110px;text-align:left;align-items:start" class="flex alignCenter">执业许可证</div>
                <div style="width:610px;align-items:flex-end;" class="flex  ">
                    <div v-if="org.hospital.license" style="width:148px;height:148px;position:relative;" class="upPicOperParent">
                        <div class="upPicOper flex alignCenter spacearound" style="width:148px;height:148px;position:absolute;top:0;left:0;">
                            <i class="el-icon-delete cursorP" @click="deleteUpPic"></i>
                        </div>
                        <img :src="$store.getters.Baseurl + org.hospital.license" style="width:100%;height:100%;" alt="">
                    </div>
                    <div v-else>
                    <common-upload @received="received" :upNumber="1" :recId="'org.hospital.license'"></common-upload>
                    <span style="padding-left:15px;">请上传小于2MB的图片</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import addressSelect from '@/components/addressSelectup'
import commonUpload from '@/components/commonUpload'
export default {
    name:'cominforOrg',
    props:{
        edit:{
            type: Boolean,
            default: false
        },
        org:{
            type: Object,
            default: () => {}
        },
        cominfor:{
            type: Object,
            default: () => {}
        },
    },
    components:{addressSelect, commonUpload},
    data(){
        return{
            orgs:{},
            watchUnber:0
        }
    },
    computed:{
        onorg(){
            const { orgs }  = this;
            return JSON.parse(JSON.stringify({orgs}))
        }
    },
    watch:{
        onorg:{
            handler(news,old) {
                var newObj = news.orgs;
                var oldObj = old.orgs;
                
                if (!oldObj.id) return; 
                if (newObj.hospital.full_name!=oldObj.hospital.full_name || newObj.hospital.organ_type!=oldObj.hospital.organ_type || newObj.hospital.manage_type!=oldObj.hospital.manage_type || newObj.hospital.level!=oldObj.hospital.level || newObj.hospital.address!=oldObj.hospital.address || newObj.hospital.license!=oldObj.hospital.license || newObj.hos_province_id!=oldObj.hos_province_id || newObj.hos_city_id!=oldObj.hos_city_id || newObj.hos_zone_id != oldObj.hos_zone_id) {
                    if (oldObj.id) {
                        this.$emit('is-button-tow',{isbutton:true,unber:2});
                    }
                }
            },
            deep: true     
        },
    },
    created(){
        this.orgs = this.org;
        this.org.hos_province_id = this.org.hospital && this.org.hospital.province_id ? this.org.hospital.province_id : null
        this.org.hos_city_id = this.org.hospital && this.org.hospital.city_id ? this.org.hospital.city_id : null
        this.org.hos_zone_id = this.org.hospital && this.org.hospital.zone_id ? this.org.hospital.zone_id : null
    },
    mounted(){
		// console.log(this.org.hos_province_id, this.org.hos_city_id, this.org.hos_zone_id)
        this.$refs.orgInfoAddress.assignaddressbinds([this.org.hos_province_id, this.org.hos_city_id, this.org.hos_zone_id])
    },
    methods:{
        /* 接收上传的图片数组 */
        received(value, field){
            this.org.hospital.license = value.join(',')
            this.$forceUpdate()
        },
        subparent(){
            return this.org
            console.log(this.org)
            this.$emit('subChildren', this.org)
        },
        proviceSE(value, field){
            if(field == 'org'){
                this.org.hos_province_id = value[0]
                this.org.hos_city_id = value[1]
                this.org.hos_zone_id = value[2]
                return
            }
        },
        deleteUpPic(){
            this.$confirm('确认删除此图片？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.org.license = ''
                this.org.hospital.license = ''
                this.$forceUpdate()
                this.$message({
                    type: 'success',
                    message: '图片已删除，点击底部保存后生效!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    .upPicOperParent{
        .upPicOper{
            display: none;
            z-index: 9999;
            color: #ffffff;
            background: rgba(0,0,0,0.6);
            display: none;
        }

        transition: all 3s;
    }
.upPicOperParent:hover .upPicOper{
        display: flex;
        transition: all .3s;
    }
    table{
        margin-top: 30px;
        tr{
            height: 66px;
            td{
                font-size: 15px;
                color: #393939;
                &:nth-child(3){
                    display: inline-block;
                    margin-left: 67px;
                    line-height: 66px;
                }
            }
        }
    }
</style>