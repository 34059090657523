<template>
    <div class="commonUpload">
        <el-upload
        :action="$store.getters.requestBaseUrl + 'Home/update_img'"
        list-type="picture-card"
        :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :before-upload="beforeAvatarUpload"
        :before-remove="beforeRemove"
        :show-file-list="false"
        :limit='upNumber'>
            <i class="el-icon-plus"></i>
        </el-upload>
        <!-- 大图 -->
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="$store.getters.Baseurl + fileList[showImg]" alt="">
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: 'commonUpload',
    props:{
        /* 上传数量 */
        upNumber: {
            type: Number,
            default: 1
        },
        /* 传入字段 */
        recId: {
            default: String,
            default: null
        },
        datas:{
            type: Array,
            default: () => []
        }
    },
    data(){
        return{
            fileList:[],
            dialogVisible:false,
            showTemp:[],
            showImg:null
        }
    },
    created(){
        if(this.datas){
            this.datas.forEach((im,ix) => {
                this.fileList.push(im)
            })
        }
    },
    methods:{
        /* 图片上传成功回调 */
        handleSuccess(response, file, fileList){
            this.showTemp.push(file.uid)
            this.fileList.push(response.data.url)
        },
        /* 图片删除回调 */
        handleRemove(file, fileList) {
            let isPut = this.fileList.indexOf(file.url)
            this.fileList.splice(isPut,1)
        },
        /* 图片放大 */
        handlePictureCardPreview(file) {
            let showPut = this.showTemp.indexOf(file.uid)
            this.showImg = showPut
            this.dialogVisible = true
        },
        /* 图片上传前限制 */
        beforeAvatarUpload(file) {
            if(parseInt(this.fileList.length) > parseInt(this.upNumber) -1){
                this.$message.warning('已达到最大上传数量，如需继续上传需删除部分图片！')
                return
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isLt2M;
        },
        /* 图片删除前提示 */
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${ file.name }？`);
        }
    },
    watch:{
        /* 监听 上传列表 */
        fileList(){
            this.$emit('received', this.fileList, this.recId)
        }
    }
}
</script>
<style lang="scss" scoped>
.commonUpload{

}
</style>