<template>
    <div class="flex alignCenter spacearound">
        <!-- 用户公共部分 -->
        <div>
        <table>
            <tr>
                <td style="width:90px;text-align:left;">姓名</td>
                <td style="width:307px;">
                    <el-input v-model="personal.real_name" placeholder="请填写姓名"></el-input>
                </td>
                <td style="width:90px;text-align:left;">单位</td>
                <td style="width:307px;">
                    <el-input v-model="personal.unit" placeholder="请填写单位"></el-input>
                </td>
            </tr>
            <tr>
                <td style="width:90px;text-align:left;">邮箱</td>
                <td style="width:307px;">
                    <el-input v-model="personal.email" placeholder="请填写邮箱"></el-input>
                </td>
                <td style="width:90px;text-align:left;">部门</td>
                <td style="width:307px;">
                    <el-select style="width:100%;" v-model="personal.branch" placeholder="请选择部门">
                        <el-option
                        v-for="item in cominfor.department"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td style="width:90px;text-align:left;">科室</td>
                <td style="width:307px;">
                    <el-select style="width:100%;" :disabled="personal.branch != '临床/医技科室'" v-model="personal.offices" placeholder="请选择科室">
                        <el-option
                        v-for="item in cominfor.office"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name">
                        </el-option>
                    </el-select>
                </td>
                <td style="width:90px;text-align:left;">职务</td>
                <td style="width:307px;">
                    <el-select style="width:100%;" v-model="personal.job" placeholder="请选择职务">
                        <el-option
                        v-for="item in cominfor.post"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td style="width:90px;text-align:left;">职称</td>
                <td style="width:307px;">
                    <el-select style="width:100%;" v-model="personal.professional" placeholder="请选择职称">
                        <el-option
                        v-for="item in cominfor.posttitle"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name">
                        </el-option>
                    </el-select>
                </td>
                <!-- <td style="width:90px;text-align:left;">联系电话</td>
                <td style="width:307px;">
                    <el-input v-model="personal.mobile" placeholder="请填联系电话"></el-input>
                </td> -->
            </tr>
        </table>
        <div class="" style="width:870px;margin-top:25px;">
            <div class="flex spacebetween " style="width:700px;">
                <div style="width:90px;text-align:left;" class="flex alignCenter">选择地区</div>
                <div class="" style="width:609px;">
					<!--  @proviceSE="proviceSE" -->
                    <address-select ref="addressSEL" :provice="'personal'" @proviceSE="proviceSE" ></address-select>
                </div>
            </div>
        </div>
        <div class="" style="width:870px;margin-top:25px;">
            <div class="flex spacebetween " style="width:700px;">
                <div style="width:90px;text-align:left;" class="flex alignCenter">详细地址</div>
                <div style="width:610px;" class="flex spacebetween ">
                    <el-input v-model="personal.address" style="width:390px" placeholder="请输入详细地址"></el-input>
                    <div></div>
                </div>
            </div>
        </div>
        <!-- 讲师部分信息 -->
        <div v-if="personal.capacity == 2">
            <div class="" style="width:870px;margin-top:25px;">
                <div class="flex spacebetween " style="width:700px;">
                    <div style="width:90px;text-align:left;" class="flex alignCenter">身份证号</div>
                    <div style="width:610px;" class="flex spacebetween ">
                        <el-input v-model="personal.lecturer_info.id_card" style="width:390px" placeholder="请输入身份证号"></el-input>
                        <div></div>
                    </div>
                </div>
            </div>
            <div class="" style="width:870px;margin-top:25px;">
                <div class="flex spacebetween " style="width:700px;">
                    <div style="width:90px;text-align:left;align-items:start;" class="flex ">个人简介</div>
                    <div style="width:610px;" class="flex spacebetween ">
                        <el-input type="textarea" maxlength="500" :show-word-limit="true" rows="6" resize="none" style="width:390px;" v-model="personal.lecturer_info.explain" placeholder="请输入个人简介"></el-input>
                        <div></div>
                    </div>
                </div>
            </div>
			<div class="" style="width:870px;margin-top:25px;">
			    <div class="flex spacebetween " style="width:700px;">
			        <div style="width:90px;text-align:left;align-items:start;" class="flex ">主要职务</div>
			        <div style="width:610px;" class="flex spacebetween ">
			            <el-input type="textarea" maxlength="20" :show-word-limit="true" rows="6" resize="none" style="width:390px;" v-model="personal.lecturer_info.duties" placeholder="请输入主要职务用于页面展示"></el-input>
			            <div></div>
			        </div>
			    </div>
			</div>
            <div class="" style="width:870px;margin-top:25px;">
                <div class="flex spacebetween " style="width:700px;">
                    <div style="width:90px;text-align:left;align-items:start;" class="flex ">社会职务</div>
                    <div style="width:610px;" class="flex spacebetween ">
                        <el-input type="textarea" maxlength="500" :show-word-limit="true" rows="6" resize="none" style="width:390px;" v-model="personal.lecturer_info.society_job" placeholder="请输入社会职务"></el-input>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import addressSelect from '@/components/addressSelectup'
export default {
    name:'cominforPersonal',
    props:{
        cominfor:{
            type: Object,
            default: () => {}
        },
        personal:{
            type: Object,
            default: () => {}
        }
    },
    data(){
        return{
            options:[],
            officesTurn: true
        }
    },
    mounted(){
        // console.log(this.personal)
        // if(!this.personal.province_id){return}
        // console.log(this.personal.province_id, this.personal.city_id, this.personal.zone_id)
        // this.$refs.addressSEL.assignaddressbinds([this.personal.province_id, this.personal.city_id, this.personal.zone_id, true])
    },
    computed:{
        branch(){
            return this.personal.branch
        }
    },
    methods:{
        assignaddressbinds(value){
			if(value[0]){
				this.$refs.addressSEL.assignaddressbinds(value)
			}else{
				this.$refs.addressSEL.assignaddressbinds([0,0,0])
			}
        },
        childrenData(){
            let arr = []
            arr[0] = this.personal.province_id
            arr[1] = this.personal.city_id
            arr[2] = this.personal.zone_id
            arr[3] = true
            return arr
        },
        proviceSE(value, field){
            if(field == 'org'){
                this.org.hos_province_id = value[0]
                this.org.hos_city_id = value[1]
                this.org.hos_zone_id = value[2]
                return
            }
            this.personal.province_id = value[0]
            this.personal.city_id = value[1]
            this.personal.zone_id = value[2]
        }
    },
    watch:{
        branch(){
            if(this.personal.branch == '临床/医技科室'){
                this.officesTurn = false
            }else{
                this.officesTurn = true
                this.personal.offices = ''
            }
        }
    },
    components:{
        addressSelect
    }
}
</script>
<style lang="scss" scoped>
table{
    margin-top: 30px;
    tr{
        height: 66px;
        td{
            font-size: 15px;
            color: #393939;
            &:nth-child(3){
                display: inline-block;
                margin-left: 67px;
                line-height: 66px;
            }
        }
    }
}
</style>