<template>
    <div class="cominforOrgPerson">
        <table>
            <tr>
                <td style="width:90px;text-align:left;">姓名</td>
                <td style="width:307px;">
                    <el-input v-model="org.real_name" placeholder="请填写姓名"></el-input>
                </td>
                <!-- <td style="width:90px;text-align:left;">{{edit ? '手机号' : '单位'}}</td>
                <td style="width:307px;">
                    <el-input v-if="edit" v-model="org.mobile" placeholder="请填写手机号"></el-input>
                    <el-input v-else v-model="org.unit" placeholder="请填写单位"></el-input>
                </td> -->
				<td style="width:90px;text-align:left;">邮箱</td>
				<td style="width:307px;">
				    <el-input v-model="org.email" placeholder="请填写邮箱"></el-input>
				</td>
				<!-- <td style="width:90px;text-align:left;">职称</td>
				<td style="width:307px;">
				    <el-select style="width:100%;" v-model="org.professional" placeholder="请选择职称">
				        <el-option
				        v-for="item in cominfor.posttitle"
				        :key="item.id"
				        :label="item.name"
				        :value="item.name">
				        </el-option>
				    </el-select>
				</td> -->
            </tr>
            <tr>
				<td style="width:90px;text-align:left;">职称</td>
				<td style="width:307px;">
				    <el-select style="width:100%;" v-model="org.professional" placeholder="请选择职称">
				        <el-option
				        v-for="item in cominfor.posttitle"
				        :key="item.id"
				        :label="item.name"
				        :value="item.name">
				        </el-option>
				    </el-select>
				</td>
                <!-- <td style="width:90px;text-align:left;">邮箱</td>
                <td style="width:307px;">
                    <el-input v-model="org.email" placeholder="请填写邮箱"></el-input>
                </td> -->
                <td style="width:90px;text-align:left;">部门</td>
                <td style="width:307px;">
                    <el-select style="width:100%;" v-model="org.branch" placeholder="请选择部门">
                        <el-option
                        v-for="item in cominfor.department"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td style="width:90px;text-align:left;">科室</td>
                <td style="width:307px;">
                    <el-select style="width:100%;" :disabled="org.branch != '临床/医技科室'" v-model="org.offices" placeholder="请选择科室">
                        <el-option
                        v-for="item in cominfor.office"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name">
                        </el-option>
                    </el-select>
                </td>
                <td style="width:90px;text-align:left;">职务</td>
                <td style="width:307px;">
                    <el-select style="width:100%;" v-model="org.job" placeholder="请选择职务">
                        <el-option
                        v-for="item in cominfor.post"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <!-- <tr> -->
                <!-- <td style="width:90px;text-align:left;">职称</td>
                <td style="width:307px;">
                    <el-select style="width:100%;" v-model="org.professional" placeholder="请选择职称">
                        <el-option
                        v-for="item in cominfor.posttitle"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name">
                        </el-option>
                    </el-select>
                </td> -->
                <!-- <td style="width:90px;text-align:left;"></td>
                <td style="width:307px;">
                </td> -->
            <!-- </tr> -->
        </table>
    </div>
</template>
<script>
export default {
    name:'cominforOrgPerson',
    props:{
        edit:{
            type: Boolean,
            default: false
        },
        org:{
            type: Object,
            default: () => {}
        },
        cominfor:{
            type: Object,
            default: () => {}
        },
    },
    data(){
        return{
            officesTurn: true,
            orgs:{}
        }
    },
    computed:{
        branch(){
            return this.org.branch
        },
        onorg(){
            const { orgs }  = this;
            return JSON.parse(JSON.stringify({orgs}))
        }
    },
    created(){
        this.orgs = this.org;
    },
    watch:{
        onorg:{
            handler(news,old) {
                var newObj = news.orgs;
                var oldObj = old.orgs;
                if (newObj.real_name!=oldObj.real_name || newObj.email!=oldObj.email || newObj.professional!=oldObj.professional || newObj.branch!=oldObj.branch || newObj.offices!=oldObj.offices || newObj.job!=oldObj.job) {
                    if (oldObj.id) {
                        this.$emit('is-button-one',{isbutton:true,unber:1});
                    }
                }
            },
            deep: true     
        },
        branch(){
            if(this.org.branch == '临床/医技科室'){
                this.officesTurn = false
            }else{
                this.officesTurn = true
                this.org.offices = ''
            }
        }
    }
}
</script>
<style lang="scss" scoped>
table{
    margin-top: 30px;
    tr{
        height: 66px;
        td{
            font-size: 15px;
            color: #393939;
            &:nth-child(3){
                display: inline-block;
                margin-left: 67px;
                line-height: 66px;
            }
        }
    }
}
</style>