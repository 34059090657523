<template>
  <div class="MyLike marginAuto"
       :class="!personal ? 'centerWidth' : ''">
    <div class="cominforTitle flex spacebetween alignCenter">
      <div class="cominforTitleText c33 fontW ">我的关注</div>
    </div>
    <div class="checkList flex spacebetween flexWrap"
         :class="personal ? 'maginR' : ''">
      <div class="checkListS flex spacearound alignCenter cursorP"
           :class="MyLike.includes(im.id) ? 'checkListSChecked' : ''"
           @click="putMyLike(im.id)"
           v-for="(im,ix) in myLikeList"
           :key="ix+'c'">
        {{im.name}}
      </div>
      <div v-for="val in 7"
           :key="val"
           style="width: 131px;"></div>
      <!-- <div class="checkListS " v-for="(im,ix) in number()" :key="ix" style="opacity:0"></div>” -->
    </div>
    <div class="clear"></div>
  </div>
</template>
<script>
import { course_type } from '@/utils/Api/homeList'
export default {
  name: 'myLike',
  props: {
    personal: {
      type: Number,
      default: 1
    },
    MyLike: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      myLikeList: [],
      orgs:{}
    }
  },
  created () {
    this.orgs = this.org;
    this.initial()
  },
  computed:{
      onorg(){
          const { orgs }  = this;
          return JSON.parse(JSON.stringify({orgs}))
      }
  },
  watch:{
      onorg:{
          handler(news,old) {
              console.log(old)
              if (!old.orgs.id) return false;
              this.$emit('is-button-three',{isbutton:true,unber:3});
          },
          deep: true     
      },
  },
  methods: {
    number () {
      const num = this.myLikeList.length % 7
      if (num == 1) {
        return 0
      }
      return 4 - parseInt(num)
    },
    /* 点击我的关注选项 */
    putMyLike (id) {
      let isPut = this.MyLike.indexOf(id)
      if (isPut == -1) {
        this.MyLike.push(id)
        return
      }
      this.MyLike.splice(isPut, 1)
    },
    async initial () {
      try {
        this.myLikeList = await course_type({ level: 2 })
        // console.log(this.myLikeList)
        if (this.myLikeList.length < 1) {
          this.$message.error('关注列表获取失败！')
        }
      } catch (e) {
        // console.log(e) 
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.maginR {
  margin-right: 170px !important;
  width: 643px !important;
}
.cominforTitle {
  width: 100%;
  height: 88px;
  border-bottom: 1px solid #dedede;
  .cominforTitleText {
    font-size: 20px;
    margin-left: 39px;
  }
  .cominforTitleSkip {
    width: 76px;
    height: 32px;
    opacity: 1;
    background: #47d7e3;
    font-size: 16px;
    color: #fff;
    margin-right: 64px;
    cursor: pointer;
  }
}
.checkListSChecked {
  color: #fff !important;
  background: #47d7e3;
}
.MyLike {
  min-height: 265px;
  background: #fff;
  margin-top: 20px;
  padding-bottom: 36px;
  .cominforTitle {
    border: none;
  }
  .checkList {
    width: 1020px;
    float: right;
    margin-right: 32px;
    min-height: 82px;
    .checkListS {
      width: 131px;
      height: 53px;
      border: 1px solid #c6c6c6;
      border-radius: 2px;
      color: #c6c6c6;
      margin-top: 29px;
    }
  }
}
</style>